
import {namespace} from 'vuex-class';
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {integer, minValue, required} from 'vuelidate/lib/validators';
import ContainerType from "@/models/ContainerType.model";
import {CONTAINER_TYPE_STORE_NAME, ContainerTypeStoreActions} from "@/store/container-type.store";
import {APPLICATION_STORE_NAME, ApplicationStoreActions, ApplicationStoreGetters} from "@/store/application.store";
import Company from "@/models/Company";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";
import { maxLength } from '@/misc/CustomValidators';
import ContainerTypeRepository from "@/api/repositories/ContainerTypeRepository";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import OnBoardingProgress from "@/models/OnBoardingProgress";

const ContainerTypeStore = namespace(CONTAINER_TYPE_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    containersCopy: {
      name: { required, maxLength: maxLength(MaxLengthValidation.CONTAINER_TYPE_NAMES) },
      lastPenaltyRate: {
        required,
        integer,
        minValue: minValue(0)
      },
      stock: {
        integer,
        minValue: minValue(0)
      },
      description: {
        maxLength: maxLength(MaxLengthValidation.CONTAINER_TYPE_DESCRIPTION)
      },
      internalInstruction: {
        maxLength: maxLength(MaxLengthValidation.CONTAINER_TYPE_INTERNAL_INSTRUCTIONS)
      }
    },
  }
})
export default class EditContainerComponent extends mixins(ErrorMessageHandlerMixin) {
  /**
   * Container Object to Edit/ Create
   */
  @Prop({default: () => new ContainerType()})
  public container!: ContainerType;

  /**
   * State Bool if this should be for Editing or Creating
   */
  @Prop({default: false})
  public isEditing!: boolean;

  /**
   * Is component loaded in modal dialog?
   */
  @Prop({default: true})
  public isModal!: boolean;

  /**
   * Create Container Action
   * @private
   */
  @ContainerTypeStore.Action(ContainerTypeStoreActions.CREATE)
  private createContainerAction!: (payload: { companyId: string, lastPenaltyRate: number, type: ContainerType, individualRequest: boolean}) => Promise<ContainerType>;

  /**
   * Update Container Action
   * @private
   */
  @ContainerTypeStore.Action(ContainerTypeStoreActions.UPDATE)
  private updateContainerAction!: (payload: { companyId: string, containerTypeId: string, lastPenaltyRate: number, containerType: ContainerType }) => Promise<ContainerType>;

  /**
   * Action to get the current selected Company
   */
  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  public company!: Company;

  @ApplicationStore.Action(ApplicationStoreActions.GET_CURRENT_COMPANY)
  private getCurrentCompany!: (id: string) => Promise<Company>;

  /**
   * Edit Object which gets changed with new Data
   * @private
   */
  private containersCopy: ContainerType = new ContainerType();

  /**
   * State Bool for a Valid form
   * @private
   */
  private isValid = true;

  /**
   * State Bool for a Loading Indicator
   * @private
   */
  private isLoading = false;

  private created() {
    this.containersCopy = ContainerType.parseFromObject({...this.container, lastPenaltyRate: 0});
  }

  private get canChangeOnlyAmount() {
    return (this.containersCopy.isDefaultType || !this.containersCopy.company) && this.isEditing;
  }

  /**
   * checks if the value of the lastPenaltyRate is above 10
   */
  private get useSpecialConditions(): boolean {
    return this.containersCopy!.lastPenaltyRate! > 10;
  }

  private async saveData() {
    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;

      // checks if the user is editing
      if (this.isEditing) {
        if(this.containersCopy.isDefaultType || !this.containersCopy.company) {
          await ContainerTypeRepository.changeContainerTypeStock(
              this.company.id,
              this.containersCopy.id!,
              this.containersCopy.stock
          );
        } else {
          // Update the Container with new Data
          await this.updateContainerAction({
            companyId: this.company.id,
            containerTypeId: this.containersCopy.id!,
            lastPenaltyRate: this.containersCopy.lastPenaltyRate!,
            containerType: this.containersCopy
          });
        }

        if(this.container.stock !== this.containersCopy.stock) {
          const progress = OnBoardingProgress.parseFromObject({
            ...this.company.onboardingProgress,
            containerAdded: true
          });

          await CompanyRepository.updateCompanyProgress(this.$route.params.id, progress);
          await this.getCurrentCompany(this.$route.params.id);
        }

        // Show Success Message
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.CONTAINER_TYPE_UPDATED');
      } else {
        // creates new container
        await this.createContainerAction({
          companyId: this.company.id,
          lastPenaltyRate: this.containersCopy.lastPenaltyRate!,
          type: this.containersCopy!,
          individualRequest: this.useSpecialConditions
        });

        // Show Success Message
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.CONTAINER_TYPE_CREATED');

        const progress = OnBoardingProgress.parseFromObject({
          ...this.company.onboardingProgress,
          containerAdded: true
        });
        await CompanyRepository.updateCompanyProgress(this.$route.params.id, progress);
        await this.getCurrentCompany(this.$route.params.id);
      }

      this.isLoading = false;
      this.dismiss(true);
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 422:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.WRONG_QUANTITY');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }
}
