import Parseable from '@/misc/Parseable';

export default class OnBoardingProgress extends Parseable {
    public appDownloaded!: boolean;
    public containerAdded!: boolean;
    public paymentAdded!: boolean;
    public registered!: boolean;

    public static parseFromObject(object: any): OnBoardingProgress {
        const progress = new OnBoardingProgress();
        Object.assign(progress, object);
        return progress;
    }

    public parseToObject(): Partial<OnBoardingProgress> {
        return { ...this };
    }
}
